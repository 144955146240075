import React from "react";
import Layout from "../components/layout";
import MyPricingCard from "../components/pricing_card";
import { Container, Row, Col } from "react-bootstrap";

export default function Pricing() {
    return (
        <Layout>
            <Container fluid>
                <Row className="justify-content-md-center">
                    <Col lg="6">
                    <div>
                        <h2 style={{ paddingBottom: `1rem`, paddingTop: `4rem`, display: `flex`, justifyContent: `center` }}>Pricing</h2>
                        <p style={{ display: `flex`, justifyContent: `center`, align: `center`, fontSize: `1.25rem`, fontWeight: 300, textAlign: `center`, paddingBottom: `2rem` }}>
                            Here is an idea of what we charge for a typical website. Each tier comes with free design, hosting setup, development and deployment. It does not include domain name purchases and renewals or hosting platform fees. 
                        </p>
                    </div>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="container">
                <Row>
                    <Col xs="12" md="6" lg="4">
                        <MyPricingCard cardTitle="$500" headerText="Single Page Site" cardBody="" buttonText="Select" footerText="Design included!" style={{ height: `450px`}}>
                            <ul class="list-unstyled mt-3 mb-4">
                                <li>100% custom</li>
                                <li>CDN image hosting</li>
                                <li>Ideal for resumes</li>
                                <li>SEO optimization</li>
                            </ul>       
                        </MyPricingCard>
                    </Col>
                    <Col xs="12" md="6" lg="4">
                        <MyPricingCard cardTitle="$2,000+" headerText="Multi-Page Site" cardBody="" buttonText="Select" footerText="Make the 20's roar again!" style={{ height: `450px`}}>
                            <ul class="list-unstyled mt-3 mb-4">
                                <li>Up to 10 pages</li>
                                <li>CDN image hosting</li>
                                <li>Phone and email support</li>
                                <li>Free changes for 3 months</li>
                            </ul>  
                        </MyPricingCard>
                    </Col>
                    <Col xs="12" md="6" lg="4">
                        <MyPricingCard cardTitle="$150/month" headerText="Technical Support" cardBody="" buttonText="Select" footerText="Peace of mind for small businesses" style={{ height: `450px`}}>
                            <ul class="list-unstyled mt-3 mb-4">
                                <li>Unlimited website updates*</li>
                                <li>Domain and hosting included</li>
                                <li>Phone and email support</li>
                                <li>Cancel anytime</li>
                            </ul>  
                        </MyPricingCard>
                    </Col>
                    
                    {/* Save this for April Fool's day */}
                    {/* <Col xs="12" md="6" lg="6">
                        <MyPricingCard cardTitle="$1,000,000,000 USD" headerText="ObamaCare Website" cardBody="" buttonText="Select" footerText="Over 50% off!" style={{ height: `450px`}}>
                            <ul class="list-unstyled mt-3 mb-4">
                                <li>No <a href="https://www.thedailybeast.com/cheats/2014/09/24/obamacare-websites-cost-over-2b">taxpayer</a> money - funded by you</li>
                                <li>Functional website</li>
                                <li>Fast turnaround time</li>
                                <li>100% secure (nobody will want to use it)</li>
                            </ul>  
                        </MyPricingCard>
                    </Col>
                    <Col xs="12" md="6" lg="6">
                        <MyPricingCard cardTitle="$69,000,000,000" headerText="Social Media Platform" cardBody="" buttonText="Select" footerText="Get your Zuck on!" style={{ height: `450px`}}>
                            <ul class="list-unstyled mt-3 mb-4">
                                <li>Complete control over content</li>
                                <li>Compete with Facebook</li>
                                <li>Baselessly ban users</li>
                                <li>Will make you feel like a god</li>
                            </ul>  
                        </MyPricingCard>
                    </Col> */}
                </Row>
            </Container>
        </Layout>
    );
}