import React from "react";
import { Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/style.css";

export default function MyPricingCard(props) {
    return (
        <Card className="custom-pricing-card" style={{ width: '360px', margin: `auto auto`, height: `26rem`, marginTop: `10px` }}>
            <Card.Header as="h5">{props.headerText}</Card.Header>
            <Card.Body style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                
                <Card.Title>{props.cardTitle}</Card.Title>
                <Card.Text>
                    {props.children}
                </Card.Text>
                <Button variant="custom-theme" style={{marginTop: `auto`}}>{props.buttonText}</Button>
            </Card.Body>
            <Card.Footer>
                <small className="text-muted">{props.footerText}</small>
            </Card.Footer>
        </Card>
    );
}